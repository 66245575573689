.alice-carousel__dots {
  position: absolute;
  right: 90px;
  bottom: 50px;
}

.alice-carousel__dots-item {
  min-width: 10px;
  min-height: 10px;
  margin: 5px;
  border-radius: 20px;
  background: gray;
  opacity: 0.5;
  transition: opacity 0.3s, width 0.3s ease-in-out; /* Added width transition */
}

.alice-carousel__dots-item.__active {
  background: white !important;
  opacity: 0.9;
  border-radius: 20px;
  height: 10px;
  width: 50px;
}

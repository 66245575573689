.productCard .textPart {
  transition: transform 0.3s ease-out;
}
.productCard:hover .textPart {
  transform: translateY(-1rem);
}

.productCard:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
